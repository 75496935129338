<template>
  <BaseHamburger class="sidebar-hamburger--css">
    <div class="sidebar-hamburger--css--toggle">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  </BaseHamburger>
</template>

<script>

export default {
  name: 'sidebar-css-hamburger',
  components: {
    BaseHamburger: () => import('./BaseHamburger'),
  },
};

</script>

<style lang="scss">

.sidebar-hamburger--css {
  overflow: hidden;
  cursor: default;

  &--toggle {
    height: 21px;
    width: 30px;
    position: relative;
    display: block;
    cursor: pointer;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
  }

  .line {
    display: block;
    position: absolute;
    background-color: #000;
    height: 3px;
    border-radius: 5px;
    opacity: 1;
    right: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      width: 30px;
      top: 0;
    }

    &:nth-child(2) {
      width: 25px;
      top: 9px;
    }

    &:nth-child(3) {
      width: 20px;
      top: 18px;
    }
  }

  &.open .sidebar-hamburger--css--toggle {
    .line {
      &:nth-child(1) {
        top: 9px;
        transform: rotate(135deg);
      }

      &:nth-child(2) {
        opacity: 0;
        right: -60px;
      }

      &:nth-child(3) {
        top: 9px;
        width: 30px;
        transform: rotate(-135deg);
      }
    }
  }
}

</style>
